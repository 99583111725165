import React, { useEffect } from 'react'
import './App.scss'
import Hero from './Components/Hero'
import Header from './Components/Header'
import Skills from './Components/Skills'
import Experience from './Components/Experience'
import Project from './Components/Project'
import Education from './Components/Education'
import Footer from './Components/Footer'
import AOS from 'aos'
import 'aos/dist/aos.css'
import ScrollToTopButton from './UI/ScrollTop'
import Contact from './Components/Contact'

export function App() {
	useEffect(() => {
		AOS.init({ duration: 3000 })
	}, [])
	return (
		<div className='App'>
			<Header />
			<Hero />
			<Project />
			<Education />
			<Skills />
			<Experience />
			<Contact />
			<Footer />
			<ScrollToTopButton />
		</div>
	)
}

export default App
