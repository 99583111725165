import React from 'react'
import IPortfolio from '../types/IPortfolio'

export default function PortfolioImage(props: IPortfolio) {
	return (
		<a
			href={props.webURL ? props.webURL : props.githubURL}
			target='_blank'
			rel='noopener noreferrer'
		>
			<div className='w-full sm:w-128 sm:mr-10 inline-flex items-center justify-center text-blue-400 flex-shrink-0'>
				{props.gif ? (
					<img
						className='bg-white sm:w-full sm:h-full rounded border hover:border-blue-700 transition ease-in duration-300'
						src={props.image}
						alt={props.image}
					/>
				) : (
					<img
						className='bg-white w-full h-56 sm:h-64 rounded border hover:border-blue-700 transition ease-in duration-300 object-cover'
						src={props.image}
						alt={props.image}
					/>
				)}

				{props.gif && (
					<img className='sm:w-64 sm:h-40' src={props.gif} alt={props.gif} />
				)}
			</div>
		</a>
	)
}
