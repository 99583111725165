import React, { Fragment } from 'react'
import Heading from '../UI/Heading'
import List from '../UI/List'
import data from '../data/about.json'

export default function Skills() {
	return (
		<Fragment>
			{/* <img src={upperWave} alt='' /> */}
			<section className='text-gray-500 bg-gray-900 body-font relative'>
				<Heading h1={'SKILLS'} h2={data.heading} animation={'fade-up'} />
				<div className='container mx-auto flex px-6 py-24 md:flex-row flex-col items-center'>
					<div className='lg:flex-grow lg:w-1/2 lg:pl-24 md:pl-16 flex flex-col items-start text-left'>
						<div className='items-stretch'>
							<Heading type={'white'} h2={'Programing Languages'} />
							{data.skills.programingLanguages.map((programingLanguages, i) => {
								return <List key={i} item={programingLanguages} />
							})}

							<Heading type={'white'} h2={'Front End'} />
							{data.skills.frontend.map((frontend, i) => {
								return <List key={i} item={frontend} />
							})}

							<Heading type={'white'} h2={'Style'} />
							{data.skills.style.map((style, i) => {
								return <List key={i} item={style} />
							})}

							<Heading type={'white'} h2={'Back End'} />
							{data.skills.backend.map((backend, i) => {
								return <List key={i} item={backend} />
							})}

							<Heading type={'white'} h2={'Database'} />
							{data.skills.database.map((database, i) => {
								return <List key={i} item={database} />
							})}

							<Heading type={'white'} h2={'Tools'} />
							{data.skills.tools.map((tools, i) => {
								return <List key={i} item={tools} />
							})}
						</div>
					</div>
				</div>
			</section>
			{/* <img src={lowerWave} alt='' /> */}
		</Fragment>
	)
}
