import React from 'react'
import Logo from '../UI/Logo'
import Copyright from '../UI/Copyright'
import SocialIcons from '../UI/SocialIcons'

export default function Footer() {
	return (
		<footer className='text-gray-500 bg-gray-900 body-font border-t-2 border-gray-600'>
			<div className='container px-6 py-8 mx-auto'>
				<div className='w-full lg:w-3/4 flex items-center sm:flex-row flex-col mx-auto'>
					<Logo />
					<Copyright
						name={'David Preece'}
						twitterUrl={'https://twitter.com/NyashaNziboi'}
					/>
					<SocialIcons
						githubURL={'https://github.com/preecedavid/'}
						// linkedInURL={'https://linkedin.com/in/davidpreecework/'}
						emailURL={'mailto:davidpreecetech@gmail.com'}
					/>
				</div>
			</div>
		</footer>
	)
}
