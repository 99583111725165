import React from 'react'
import PortfolioImage from './PortfolioImage'
import IPortfolio from '../types/IPortfolio'
import skillicons from '../data/skillicons.json'

export default function PortfolioCard(props: IPortfolio) {
	const { tech } = props
	const techs = tech?.split(',')
	const skills: { [key: string]: string } | any = skillicons

	return (
		<div className={props.openTab === props.platform ? 'block' : 'hidden'}>
			<div className='flex items-center mx-auto border-b pb-10 mb-8 sm:mb-10 border-gray-800 lg:flex-row flex-col'>
				<PortfolioImage
					image={props.image}
					gif={props.gif}
					githubURL={props.githubURL}
					webURL={props.webURL}
				/>
				<div className='flex-grow sm:text-left mt-6 sm:mt-0'>
					<h2 className='text-white text-lg title-font font-medium mb-2'>
						{props.name}
					</h2>
					<p className='leading-relaxed text-base whitespace-pre-line'>
						{props.desc}
					</p>
					<div className='mt-3 flex-wrap text-blue-500 inline-flex items-center'>
						{techs?.map((tec, i) => (
							<img
								src={skills[tec.trim()]}
								key={i}
								alt=''
								className='pr-3 mt-3 skillicon'
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}
