import React, { Fragment, useState } from 'react'
import Heading from '../UI/Heading'
import PortfolioCard from '../UI/PortfolioCard'
import Parallel from '../assets/images/portfolio/project-parallel.png'
import roofStock from '../assets/images/portfolio/project-roofstock.png'
import spaceList from '../assets/images/portfolio/project-spacelist.png'
import TaskDent from '../assets/images/portfolio/TaskDent.jpg'
import Chimney from '../assets/images/portfolio/project-chimney.png'
import Auction from '../assets/images/portfolio/project-auction.png'
import PixelMind from '../assets/images/portfolio/project-pixelmind.png'
import Link from '../UI/Link'
import data from '../data/portfolio.json'

export default function Project() {
	// eslint-disable-next-line
	const [openTab, setOpenTab] = useState('web')

	return (
		<Fragment>
			{/* <img src={upperWave} alt='' /> */}
			<section className='text-gray-400 bg-gray-900 body-font'>
				<Heading h1={'PROJECT'} h2={data.heading} animation={'fade-up'} />

				<div className='container px-5 py-12 mx-auto' data-aos='fade-in'>
					{/* <br /> */}
					<PortfolioCard
						name={'Auction'}
						desc={
							'Developed online real estate platform using React, Redux, Typescript.\n Created front-end services to interact with GraphQL and REST endpoints written in Python and Java.\n Built toolkit core libraries and base components using React, React Hook, Semantic UI, SCSS and TypeScript.\n Worked closely with SEO teams on implementing analytics and tagging systems and wrote unit tests using Jest.'
						}
						tech={'react, redux, typescript, semanticui, sass, python'}
						githubURL={''}
						webURL={'https://www.auction.com/'}
						gif={''}
						image={Auction}
						platform={'web'}
						openTab={openTab}
					/>

					<PortfolioCard
						name={'Chimney'}
						desc={
							'Built and maintained the real-time analytics dashboard that allows users to monitor views, clicks, and engagement metrics for each calculator they create. \n Worked closely with the UI/UX designer to implement responsive designs for mobile, tablet, and desktop devices, as well as building out the calculator editor that allowed users to change the calculation formulas and styles of the calculator to meet their business needs.\n Also integrated with AWS to store and retrieve the analytics data, as well as built out the UI components to display the data in an easily digestible format.'
						}
						tech={'vue, nuxt, chartjs, aws'}
						githubURL={''}
						webURL={'https://chimney.io/'}
						gif={''}
						image={Chimney}
						platform={'web'}
						openTab={openTab}
					/>
					<PortfolioCard
						name={'TaskDent'}
						desc={
							'Developed the front-end using Angular and Typescript, created responsive UI components, implemented business logic, and ensured code quality through testing and adherence to clean code principles. \n Worked on back-end using PHP and Laravel, building RESTful APIs for data processing and storage in Amazon S3, designing database schema, and ensuring the security and scalability of the system. \n Integrated payment processing functionalities using Stripe and implemented chart visualizations with ChartJS.'
						}
						tech={'angular, rxjs, chartjs, jasmine, amazons3, php, laravel'}
						githubURL={''}
						webURL={'https://www.taskdent.com/'}
						gif={''}
						image={TaskDent}
						platform={'web'}
						openTab={openTab}
					/>

					<PortfolioCard
						name={'Pixelmind'}
						desc={
							'Built NFT marketplace UI app using React and Next.js and interact with designers and product managers to confirm UI/UX was designed properly in business wise. \n Integration Web3.js for NFT minting, trading logic.\n Built Node + Typescript + Graphql backend system for NFT aggregation mechanism.'
						}
						tech={'nextjs, react, web3js, node, typescript, graphql'}
						githubURL={''}
						webURL={'https://beta.pixelmind.ai/'}
						gif={''}
						image={PixelMind}
						platform={'web'}
						openTab={openTab}
					/>

					<PortfolioCard
						name={'Parallel - Analytics'}
						desc={
							'Built React components and implementing data visualization using ReChart and Charts.js. Utilized technologies such as Typescript, Styled components, and CSS to create responsive and visually appealing user interfaces.\nDeveloping server-side functionality using Node and Express.js to retrieve and process data for display on the analytics pages.\nWriting unit tests to ensure code functionality and quality.'
						}
						tech={'node, express, chartjs, typescript, styledcomponents, css'}
						githubURL={''}
						webURL={'https://analytics.parallel.fi/'}
						gif={''}
						image={Parallel}
						platform={'web'}
						openTab={openTab}
					/>
					<PortfolioCard
						name={'SpaceList'}
						desc={
							'Developed a real estate marketplace using Postgres, AngularJS, Elasticsearch, Rails, PostgreSQL, memcached, and S3 on AWS.\n Built numerous Ruby On Rails features including the Worlds fastest administrative-level independent commercial suite search (Which received SRED funding) and a lease reporting system.\n Implemented UI/UX designs, including user interactions, animations, cross-browser compatibility.\n Developed an internal dashboard for best insourcing results. \n Implemented the connection to the backend REST API.'
						}
						tech={'postgres, rails, amazons3, angular, elasticsearch'}
						githubURL={''}
						webURL={'https://www.spacelist.ca/'}
						gif={''}
						image={spaceList}
						platform={'web'}
						openTab={openTab}
					/>

					<PortfolioCard
						name={'Roofstock'}
						desc={
							'Declared reusable blocks of styling using React, NextJS, and Styled Components to reduce complicated CSS file structures.\n Responsible for the primary customer facing website and back-end APIs used to search properties and process millions of dollars of real-estate transactions.\n Creation of microservices and migration from legacy monolith into the new microservice architecture.'
						}
						tech={
							'react, nextjs, styledcomponents, typescript, postgres, tailwindcss, docker'
						}
						githubURL={''}
						webURL={'https://www.roofstock.com/'}
						gif={''}
						image={roofStock}
						platform={'web'}
						openTab={openTab}
					/>
					<Link
						href={'https://github.com/preecedavid/'}
						text={'More on GitHub...'}
					/>
				</div>
			</section>
			{/* <img src={lowerWave} alt='' /> */}
		</Fragment>
	)
}
