import React, { useState, useEffect } from 'react'
import ArrowUp from '../assets/icons/arrow-up.svg'

const ScrollToTopButton = () => {
	const [isVisible, setIsVisible] = useState(false)

	const handleScroll = () => {
		const scrollY = window.scrollY

		// Show the button when the user scrolls down 200px or more
		if (scrollY > 200) {
			setIsVisible(true)
		} else {
			setIsVisible(false)
		}
	}

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)

		return () => {
			// Clean up the event listener when the component is unmounted
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	return (
		<div
			className={`w-fit p-2 text-blue-600 font-bold fixed cursor-pointer ${
				isVisible ? 'visible' : 'hidden'
			}`}
			onClick={scrollToTop}
			style={{ bottom: '20px', right: '20px' }} // Adjust position as needed
		>
			<img src={ArrowUp} alt='' />
		</div>
	)
}

export default ScrollToTopButton
