import React, { Fragment, useEffect, useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import Heading from '../UI/Heading'
// import upperWave from '../assets/icons/background/upper-wave-1.svg'
// import lowerWave from '../assets/icons/background/lower-wave-1.svg'

export default function Contact() {
	const emailRef = useRef<HTMLInputElement>(null)
	const nameRef = useRef<HTMLInputElement>(null)
	const messageRef = useRef<HTMLTextAreaElement>(null)
	const [loading, setLoading] = useState(false)

	useEffect(() => emailjs.init('ZZQlO8w4NybMhBLUu'), [])

	const handleSubmit = async (e: any) => {
		e.preventDefault()
		const serviceId = 'service_orzwwqa'
		const templateId = 'template_n2zy7k2'
		try {
			setLoading(true)
			await emailjs.send(serviceId, templateId, {
				name: nameRef.current?.value,
				email: emailRef.current?.value,
				message: messageRef.current?.value,
			})
			alert('email successfully sent check inbox')
			if (nameRef.current && emailRef.current && messageRef.current) {
				nameRef.current.value = ''
				emailRef.current.value = ''
				messageRef.current.value = ''
			}
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}

	return (
		<Fragment>
			{/* <img src={upperWave} alt='' /> */}
			<section className='text-gray-500 bg-gray-900 body-font relative overflow-hidden pt-10'>
				<Heading h1={'CONTACT'} animation={'fade-up'} />
				<div className='container px-6 pt-10 pb-24 mx-auto' data-aos='fade-in'>
					<div className='rounded overflow-hidden w-full lg:w-3/4 mx-auto'>
						<iframe
							src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d606.4727101004319!2d-96.76211017521268!3d32.79894224305192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e98b1bc73abb7%3A0x1578004e452bed6b!2s5123%20Reiger%20Ave%2C%20Dallas%2C%20TX%2075214!5e1!3m2!1sen!2sus!4v1714688769996!5m2!1sen!2sus'
							title='Map Location'
							width='100%'
							height='400'
							frameBorder='0'
							style={{ border: 0 }}
							loading='lazy'
							className='rounded-xl'
						></iframe>
					</div>
					<form
						onSubmit={handleSubmit}
						className='w-full lg:w-3/4 pt-8 pb-24 mx-auto flex flex-col gap-8'
					>
						<div className='flex flex-col md:flex-row gap-6'>
							<input
								type='text'
								name='name'
								ref={nameRef}
								placeholder='Full Name'
								className='md:flex-1 p-4 b-input text-white bg-gray-900 rounded-md text-lg'
							/>
							<input
								type='text'
								name='email'
								ref={emailRef}
								placeholder='Your Email'
								className='md:flex-1 p-4 b-input text-white bg-gray-900 rounded-md text-lg'
							/>
						</div>
						<textarea
							name='message'
							ref={messageRef}
							placeholder='Message'
							className='flex-1 p-4 b-input text-white bg-gray-900 rounded-md text-lg'
						></textarea>
						<div className='flex justify-end w-fit'>
							{loading ? (
								<button
									disabled
									type='submit'
									className='text-white bg-blue-500 hover:bg-blue-600 border-0 py-2 px-6 focus:outline-none rounded inline-flex text-lg'
								>
									Loading
								</button>
							) : (
								<button
									type='submit'
									className='text-white bg-blue-500 hover:bg-blue-600 border-0 py-2 px-6 focus:outline-none rounded inline-flex text-lg'
								>
									Send Message
								</button>
							)}
						</div>
					</form>
				</div>
			</section>
			{/* <img src={upperWave} alt='' /> */}
		</Fragment>
	)
}
