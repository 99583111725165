import React from 'react'
import Dev from '../assets/images/david-banner.jpg'
import SocialIcons from '../UI/SocialIcons'
import data from '../data/hero.json'

export default function Hero() {
	return (
		<section
			id='ABOUT'
			data-aos='fade-in'
			className='text-gray-700 body-font md:py-8'
		>
			<div className='container mx-auto px-6 py-4 md:py-8'>
				<h1 className='title-font hero-text text-center mb-4 font-semibold text-gray-900'>
					David Preece | Senior Full Stack Developer
				</h1>
			</div>
			<div className='container mx-auto flex px-6 md:pb-24 pb-16 xl:flex-row flex-col-reverse items-center'>
				<div className='lg:flex-grow xl:w-1/2 lg:pr-12 lg:pl-0 md:px-16 flex flex-col xl:items-start xl:text-left mb-16 md:mb-0 items-center text-center'>
					<h2 className='sm:text-2xl text-1xl md:my-8 mt-4 leading-relaxed'>
						{data.h2}
					</h2>

					<div className='flex justify-center my-8'>
						<SocialIcons
							githubURL={'https://github.com/preecedavid/'}
							// linkedInURL={'https://linkedin.com/in/davidpreecework/'}
							emailURL={'mailto:davidpreecetech@gmail.com'}
						/>
					</div>
				</div>
				<div className='lg:max-w-lg lg:w-full md:w-1/2 w-5/6 object-cover object-center rounded-full'>
					<img className='rounded-3xl' src={Dev} alt='' />
				</div>
			</div>
		</section>
	)
}
