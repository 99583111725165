import React, { useState } from 'react'
import HeaderLink from '../UI/HeaderLink'

export default function Header() {
	const [toggle, setToggle] = useState(false)

	const handleToggleMenu = () => {
		setToggle(prev => !prev)
	}

	return (
		<div>
			<header className='text-gray-500 bg-gray-900 body-font flex px-5 py-3 md:py-5'>
				<div className='container mx-auto flex flex-wrap flex-row items-center justify-between lg:px-32'>
					<div className='w-full flex md:hidden items-center justify-between'>
						<button
							className='inline-flex p-3 hover:bg-gray-800 rounded md:hidden text-white'
							onClick={handleToggleMenu}
						>
							{toggle ? (
								<svg
									className='w-6 h-6'
									fill='none'
									stroke='currentColor'
									viewBox='0 0 24 24'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth='2'
										d='M6 18L18 6M6 6l12 12'
									/>
								</svg>
							) : (
								<svg
									className='w-6 h-6'
									fill='none'
									stroke='currentColor'
									viewBox='0 0 24 24'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth='2'
										d='M4 6h16M4 12h16M4 18h16'
									/>
								</svg>
							)}
						</button>
						<a
							href='/files/DavidResume.pdf'
							className='md:hidden rounded inline-flex text-lg items-center lg:ml-auto'
							target='_blank'
						>
							<svg
								className='w-4 h-4 mr-2'
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 20 20'
								fill='currentColor'
							>
								<path
									fillRule='evenodd'
									d='M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z'
									clipRule='evenodd'
								/>
							</svg>
							<span>Resume</span>
						</a>
					</div>
					<nav
						className={`${
							toggle ? 'flex' : 'hidden'
						} md:flex md:items-center w-full`}
					>
						<div className='md:w-full flex md:flex-row flex-col items-start py-6 md:py-0 gap-4 justify-between'>
							<HeaderLink to={'PROJECT'} name={'Project'} />
							<HeaderLink to={'EDUCATION'} name={'Education'} />
							<HeaderLink to={'SKILLS'} name={'Skills'} />
							<HeaderLink to={'EXPERIENCE'} name={'Experience'} />
							<HeaderLink to={'CONTACT'} name={'Contact'} />
							<div className='hidden md:flex'>
								<a
									href='/files/DavidResume.pdf'
									className='flex rounded inline-flex text-lg items-center lg:ml-auto'
									target='_blank'
								>
									<svg
										className='w-4 h-4 mr-2'
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 20 20'
										fill='currentColor'
									>
										<path
											fillRule='evenodd'
											d='M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z'
											clipRule='evenodd'
										/>
									</svg>
									<span>Resume</span>
								</a>
							</div>
						</div>
					</nav>
				</div>
			</header>
		</div>
	)
}
