import React, { Fragment } from 'react'

export default function Logo() {
	return (
		<Fragment>
			<a
				className='flex title-font font-medium items-center text-white mb-4 md:mb-0'
				href='/'
			>
				<span className='text-xl'>Senior Full Stack Developer</span>
			</a>
		</Fragment>
	)
}
